const dev = {
	API_ENDPOINT_URL: "http://localhost:8090/tec/sempre-em-campo/api",
	PUBLIC_PREFIX_PATH: "",
	API_ENDPOINT_PDF: "http://localhost:8090/sempre"
};

const prod1 = {
	API_ENDPOINT_URL: "https://sempresementes.dimo.com.br/tec/sempre-em-campo/api",
	PUBLIC_PREFIX_PATH: "",
	API_ENDPOINT_PDF: "https://sempresementes.dimo.com.br/tec/sempre"
};

const prod2 = {
	API_ENDPOINT_URL: "https://sempreagtech.dimo.com.br/tec/sempre-em-campo/api",
	PUBLIC_PREFIX_PATH: "",
	API_ENDPOINT_PDF: "https://sempreagtech.dimo.com.br/tec/sempre"
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/sempre-produce-back/sempre-em-campo/api",
	PUBLIC_PREFIX_PATH: "/sempre",
	API_ENDPOINT_PDF: "https://homolog.dimo.com.br/sempre-produce-back/sempre"
};

const getClientEnvironment = () => {
	const currentHostname = window.location.hostname;

	// Define regras de correspondência para o hostname do cliente
	const rules = {
		"localhost": "development",
		"sempresementes.dimo.com.br": "production1",
		"sempreagtech.dimo.com.br": "production2",
		"homolog.dimo.com.br": "test"
	};

	const clientEnvironment = rules[currentHostname] || "development";

	switch (clientEnvironment) {
		case "development":
			return dev;
		case "production1":
			return prod1;
		case "production2":
			return prod2;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getClientEnvironment();
