import fetch from "auth/FetchInterceptor";
import { UPDATE_USER } from "redux/constants/User";
import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";
import { UPDATE_PERMISSOES } from "redux/constants/Permissoes";
import store from "redux/store";

const MenuService = {};

/*
    Injeta na store do usuário os dados do menu
*/
// TODO: após clonar o skeleton, ajustar essa função
MenuService.populateMenuData = async function () {
	const menuData = await MenuService.getMenuData();

	store.dispatch({
		type: UPDATE_USER,
		id: menuData.user.id,
		name: menuData.user.nome,
		role: "Usuário",
		email: menuData.user.email,
		grupo_usuario: menuData.user?.grupo_usuario,
	});

	store.dispatch({
		type: UPDATE_NOTIFICATIONS,
		enderecosAValidar: menuData.notificacoes.total_enderecos_a_validar,
		hibridosComErro: menuData.notificacoes.total_erros_hibridos,
		consultoresComErro: menuData.notificacoes.total_erros_usuario,
		produtoresComErro: menuData.notificacoes.total_erros_produtores,
		pedidosComErro: menuData.notificacoes.total_erros_pedidos,
		pedidosComErroLead: menuData.notificacoes.total_erros_pedidos_lead,
		comissoesComErro: menuData.notificacoes.total_erros_comissoes,
		somaErrosSap:
			menuData.notificacoes.total_erros_hibridos +
			menuData.notificacoes.total_erros_usuario +
			menuData.notificacoes.total_erros_produtores +
			menuData.notificacoes.total_erros_pedidos +
			menuData.notificacoes.total_erros_pedidos_lead +
			menuData.notificacoes.total_erros_comissoes +
			menuData.notificacoes.total_enderecos_a_validar,
	});

	store.dispatch({
		type: UPDATE_PERMISSOES,
		permissoes: menuData.permissoes,
	});
};

// TODO: após clonar o skeleton, ajustar essa função
MenuService.getMenuData = function () {
	return fetch({
		url: "/v1/rest/menu",
		method: "get",
	});
};

export default MenuService;
