import { all, takeEvery, put, fork } from "redux-saga/effects";
import { AUTH_TOKEN, SIGNOUT, AUTHENTICATED } from "../constants/Auth";
import { showAuthMessage, signOutSuccess } from "../actions/Auth";

import MenuService from "services/MenuService";

export function* signIn() {
	yield takeEvery(AUTHENTICATED, function* (payload) {
		try {
			localStorage.setItem(AUTH_TOKEN, payload.token);
			MenuService.populateMenuData();
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
		try {
			localStorage.removeItem(AUTH_TOKEN);
			yield put(signOutSuccess());
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export default function* rootSaga() {
	yield all([fork(signIn), fork(signOut)]);
}
