import React from "react";

import { Icons } from "assets/svg/icons";

export function Icon({ name, ...props }) {
	name = name.toLowerCase().replace(/-+([a-z])/g, (_, initial) => {
		return initial.toUpperCase();
	});

	const iconProps = Icons[name];

	return (
		<SvgIcon
			{...iconProps}
			{...props}
			style={{ ...iconProps.style, ...props.style }}
		/>
	);
}

const styles = {
	svg: {
		alignItems: "center",
		fill: "#455560",
		height: "1em",
		width: "1em",
	},
};

export function SvgIcon({ height, path, width, ...props }) {
	const style = { ...styles.svg, ...props.style };

	return (
		<span role="img" className="anticon">
			<svg
				xlink="http://www.w3.org/2000/svg"
				viewBox={`0 0 ${width.toString()} ${height.toString()}`}
				style={style}
			>
				<g>
					<path className="st0" d={path} />
				</g>
			</svg>
		</span>
	);
}
