import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import JwtAuthService from "services/JwtAuthService";
import MenuService from "services/MenuService";

/**
 * @typedef {{
 * isLoading: boolean;
 * setIsLoading: (isLoading: boolean) => void;
 * }} AppViewProps
 */

export const AppViews = () => {
	let intervalId = useRef(null);

	const populateMenu = async () => {
		try {
			if (JwtAuthService.isLogged()) {
				await MenuService.populateMenuData();
			}
		} catch (e) {}

		intervalId.current = setInterval(() => {
			MenuService.populateMenuData();
		}, 30000);
	};

	useEffect(() => {
		populateMenu();

		return () => {
			clearInterval(intervalId.current);
		};
	}, []);

	return (
		<Suspense fallback={<Loading cover="content" />}>
			<Switch>
				<Route
					path={`${APP_PREFIX_PATH}/dashboard`}
					component={lazy(() => import(`./dashboard`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/list-comissoes-por-desconto`}
					component={lazy(() =>
						import(`./list-comissoes-por-desconto`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/comissao-desconto/:id`}
					component={lazy(() => import(`./comissao-desconto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/comissao-desconto`}
					component={lazy(() => import(`./comissao-desconto`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/notificacao`}
					component={lazy(() => import(`./notificacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-campanhas`}
					component={lazy(() => import(`./lista-campanhas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/campanha/:id`}
					component={lazy(() => import(`./campanha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/campanha`}
					component={lazy(() => import(`./campanha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-motivo-visita-comercial`}
					component={lazy(() =>
						import(`./lista-motivo-visita-comercial`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-visita-comercial/:id`}
					component={lazy(() => import(`./motivo-visita-comercial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-visita-comercial`}
					component={lazy(() => import(`./motivo-visita-comercial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-origem`}
					component={lazy(() => import(`./lista-origem`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/origem/:id`}
					component={lazy(() => import(`./origem`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/origem`}
					component={lazy(() => import(`./origem`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-empresa`}
					component={lazy(() => import(`./lista-empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/empresa/:id`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/empresa`}
					component={lazy(() => import(`./empresa`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-interesse`}
					component={lazy(() => import(`./lista-interesse`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/interesse/:id`}
					component={lazy(() => import(`./interesse`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/interesse`}
					component={lazy(() => import(`./interesse`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-cultura`}
					component={lazy(() => import(`./lista-cultura`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cultura/:id`}
					component={lazy(() => import(`./cultura`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/cultura`}
					component={lazy(() => import(`./cultura`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-micro-regiao`}
					component={lazy(() => import(`./lista-micro-regiao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/micro-regiao/:id`}
					component={lazy(() => import(`./micro-regiao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/micro-regiao`}
					component={lazy(() => import(`./micro-regiao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-metas`}
					component={lazy(() => import(`./lista-metas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/metas/:id`}
					component={lazy(() => import(`./metas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/metas`}
					component={lazy(() => import(`./metas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-motivo-perda`}
					component={lazy(() => import(`./lista-motivo-perda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-perda/:id`}
					component={lazy(() => import(`./motivo-perda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/motivo-perda`}
					component={lazy(() => import(`./motivo-perda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tratamentos`}
					component={lazy(() => import(`./lista-tratamentos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tratamento/:id`}
					component={lazy(() => import(`./tratamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tratamento`}
					component={lazy(() => import(`./tratamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-unidades-medida`}
					component={lazy(() => import(`./lista-unidades-medida`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/unidade-medida/:id`}
					component={lazy(() => import(`./unidade-medida`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/unidade-medida`}
					component={lazy(() => import(`./unidade-medida`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-formas-pagamento`}
					component={lazy(() => import(`./lista-formas-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/forma-pagamento/:id`}
					component={lazy(() => import(`./forma-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/forma-pagamento`}
					component={lazy(() => import(`./forma-pagamento`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-tabela-precos`}
					component={lazy(() => import(`./lista-tabela-precos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tabela-preco/:id`}
					component={lazy(() => import(`./tabela-preco`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/tabela-preco`}
					component={lazy(() => import(`./tabela-preco`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-hibridos`}
					component={lazy(() => import(`./lista-hibridos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/hibrido/:id`}
					component={lazy(() => import(`./hibrido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/hibrido`}
					component={lazy(() => import(`./hibrido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-leads`}
					component={lazy(() => import(`./lista-leads`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lead/:id`}
					component={lazy(() => import(`./lead`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lead`}
					component={lazy(() => import(`./lead`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-usuarios`}
					component={lazy(() => import(`./lista-usuarios`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-grupos-usuario`}
					component={lazy(() => import(`./lista-grupos-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario/:id`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/usuario`}
					component={lazy(() => import(`./usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario/:id`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/grupo-usuario`}
					component={lazy(() => import(`./grupo-usuario`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-regionais`}
					component={lazy(() => import(`./lista-regionais`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regional/:id`}
					component={lazy(() => import(`./regional`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regional`}
					component={lazy(() => import(`./regional`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-daninhas`}
					component={lazy(() => import(`./lista-daninhas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/daninha/:id`}
					component={lazy(() => import(`./daninha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/daninha`}
					component={lazy(() => import(`./daninha`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-produtor/:id`}
					component={lazy(() => import(`./detalhes-produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-produtor`}
					component={lazy(() => import(`./detalhes-produtor`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-doencas`}
					component={lazy(() => import(`./lista-doencas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-agroquimicos`}
					component={lazy(() => import(`./lista-agroquimicos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agroquimico/:id`}
					component={lazy(() => import(`./agroquimico`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/agroquimico`}
					component={lazy(() => import(`./agroquimico`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-fazendas`}
					component={lazy(() => import(`./lista-fazendas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-visitas`}
					component={lazy(() => import(`./lista-visitas`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/doenca/:id`}
					component={lazy(() => import(`./doenca`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/doenca`}
					component={lazy(() => import(`./doenca`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-produtores`}
					component={lazy(() => import(`./lista-produtores`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-pedidos`}
					component={lazy(() => import(`./lista-pedidos`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-ocorrencias`}
					component={lazy(() => import(`./lista-ocorrencias`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/ocorrencia/:id`}
					component={lazy(() => import(`./ocorrencia`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/ocorrencia`}
					component={lazy(() => import(`./ocorrencia`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/coletar-resposta-cliente/:id`}
					component={lazy(() => import(`./coletar-resposta-cliente`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pesquisa-sobre-ocorrencia/:id`}
					component={lazy(() =>
						import(`./pesquisa-sobre-ocorrencia`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-ocorrencia/:id`}
					component={lazy(() => import(`./detalhes-ocorrencia`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-visita/:id`}
					component={lazy(() => import(`./detalhes-visita`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-hibridos-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-hibridos-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/hibrido-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/hibrido-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-usuarios-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-usuarios-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/usuario-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/usuario-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-produtores-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-produtores-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/produtor-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/produtor-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-pedidos-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-pedidos-erro-lead`}
					component={lazy(() =>
						import(`./integracao-sap/lista-pedidos-erro-lead`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/pedido-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/pedido-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-comissoes-erro`}
					component={lazy(() =>
						import(`./integracao-sap/lista-comissoes-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/comissao-erro/:id`}
					component={lazy(() =>
						import(`./integracao-sap/comissao-erro`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-pedido/:id`}
					component={lazy(() => import(`./detalhes-pedido`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/integracao-sap/lista-enderecos-validar`}
					component={lazy(() =>
						import(`./integracao-sap/lista-enderecos-validar`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/reposicoes-estoque`}
					component={lazy(() => import(`./lista-reposicao-estoque`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/itens-reposicao-estoque/:id`}
					component={lazy(() =>
						import(`./lista-reposicao-estoque-item`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/itens-reposicao-estoque`}
					component={lazy(() =>
						import(`./lista-reposicao-estoque-item`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/giros-venda`}
					component={lazy(() => import(`./lista-giro-venda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/relatorio`}
					component={lazy(() => import(`./relatorio`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/exportacao`}
					component={lazy(() => import(`./exportacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-estimulador-venda`}
					component={lazy(() => import(`./lista-estimulador-venda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estimulador-venda/:id`}
					component={lazy(() => import(`./estimulador-venda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/estimulador-venda`}
					component={lazy(() => import(`./estimulador-venda`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-cards-aprovacao`}
					component={lazy(() => import(`./lista-cards-aprovacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/card-aprovacao/:id`}
					component={lazy(() => import(`./card-aprovacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-filial`}
					component={lazy(() => import(`./regras-sap-filial`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-utilizacao`}
					component={lazy(() => import(`./regras-sap-utilizacao`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-operacao-interna`}
					component={lazy(() =>
						import(`./regras-sap-operacao-interna`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/regras-sap-centro-custo`}
					component={lazy(() => import(`./regras-sap-centro-custo`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-resultados-comerciais`}
					component={lazy(() =>
						import(`./lista-resultados-comerciais`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-resultado-comercial/:id`}
					component={lazy(() =>
						import(`./detalhes-resultado-comercial`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/detalhes-resultado-comercial`}
					component={lazy(() =>
						import(`./detalhes-resultado-comercial`),
					)}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/lista-geracao-card`}
					component={lazy(() => import(`./lista-geracao-card`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/gerar-card/:id`}
					component={lazy(() => import(`./gerar-card`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/migracao-clientes`}
					component={lazy(() => import(`./migracao-clientes`))}
				/>
				<Route
					path={`${APP_PREFIX_PATH}/pdf-de-manual`}
					component={lazy(() => import(`./pdf-de-manual`))}
				/>
				<Redirect
					from={`${APP_PREFIX_PATH}`}
					to={`${APP_PREFIX_PATH}/dashboard`}
				/>
			</Switch>
		</Suspense>
	);
};

export default React.memo(AppViews);
