export const Icons = {
	boxCheck: {
		height: 512,
		path: "M183 376.1C187.7 381.7 193.9 384 200 384s12.28-2.344 16.97-7.031l96-96c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L200 326.1L168.1 295c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L183 376.1zM439.8 145.4l-45.43-80.76C383 44.47 361.7 32 338.6 32H109.4c-23.12 0-44.45 12.47-55.78 32.62L8.219 145.4C2.83 154.1 0 165.8 0 176.8V416c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V176.8C448 165.8 445.2 154.1 439.8 145.4zM247 80h91.55c5.771 0 11.12 3.125 13.95 8.154l39.67 70.52h-145.2V80zM95.48 88.15C98.31 83.13 103.7 80 109.4 80h89.59v78.67H55.82L95.48 88.15zM400 416c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16V206.7h352V416z",
		width: 448,
	},
	boxesStacked: {
		height: 512,
		path: "M208 224h160C394.5 224 416 202.5 416 176v-128C416 21.49 394.5 0 368 0h-160C181.5 0 160 21.49 160 48v128C160 202.5 181.5 224 208 224zM208 48H256v64L288 96l32 16v-64h48v128h-160V48zM208 288h-160C21.49 288 0 309.5 0 336v128C0 490.5 21.49 512 48 512h160C234.5 512 256 490.5 256 464v-128C256 309.5 234.5 288 208 288zM208 464h-160v-128H96v64L128 384l32 16v-64h48V464zM528 288h-160C341.5 288 320 309.5 320 336v128c0 26.51 21.49 48 48 48h160c26.51 0 48-21.49 48-48v-128C576 309.5 554.5 288 528 288zM528 464h-160v-128H416v64l32-16l32 16v-64h48V464z",
		width: 576,
	},
	clockRotateLeft: {
		height: 512,
		path: "M256 32C176.2 32 103.7 75.57 64 142.1V56C64 42.75 53.25 32 40 32S16 42.75 16 56v144C16 213.3 26.75 224 40 224h128C181.3 224 192 213.3 192 200S181.3 176 168 176H99.54C129.2 118.3 189.5 79.96 256 79.96c97.03 0 176 79.01 176 176s-78.97 175.1-176 175.1c-41.09 0-81.09-14.44-112.6-40.72c-10.16-8.5-25.31-7.156-33.81 3.062c-8.5 10.19-7.125 25.31 3.062 33.81C152.8 461.6 203.7 480 256 480c123.5 0 224-100.5 224-224S379.5 32 256 32zM256 128C242.8 128 232 138.8 232 152V272c0 7.75 3.741 15.03 10.04 19.53l56 40C302.3 334.6 307.2 336 311.1 336c7.484 0 14.86-3.5 19.55-10.06c7.703-10.78 5.203-25.75-5.578-33.47L280 259.7L280 152C280 138.8 269.3 128 256 128z",
		width: 512,
	},
	commentsDollar: {
		height: 512,
		path: "M212.7 159.5L207.1 158.1c-20.32-5.973-19.83-8.732-19.18-12.56c1.178-6.695 14.22-8.33 25.94-6.535c4.795 .7227 10.06 2.41 15.08 4.152c8.906 3.08 18.71-1.662 21.82-10.58C254.7 123.7 250 113.9 241.1 110.8C234.9 108.6 229.8 107.3 225.1 106.3v-9.113C225.1 87.69 217.5 80 208 80S190.9 87.69 190.9 97.14v8.564C171.6 109.8 158.1 121.8 155 139.7c-6.348 36.72 28.45 46.93 43.3 51.29l4.982 1.447C228.4 199.7 227.1 202.1 227.2 206.4c-1.18 6.697-14.22 8.357-25.98 6.535c-5.947-.8828-13.55-3.615-20.28-6.025L177.1 205.5C168.2 202.5 158.4 207.1 155.3 216C152.1 224.9 156.8 234.7 165.7 237.9l3.656 1.287c6.648 2.391 14.04 4.791 21.48 6.438v9.232C190.9 264.3 198.5 272 208 272s17.14-7.688 17.14-17.14V246.1c19.17-4.109 32.75-15.88 35.85-33.8C267.4 175.2 231.8 164.1 212.7 159.5zM599.6 443.7C624.8 413.9 640 376.6 640 336C640 238.8 554 160 448 160c-.3145 0-.6191 .041-.9336 .043C447.5 165.3 448 170.6 448 176c0 98.62-79.68 181.2-186.1 202.5C282.7 455.1 357.1 512 448 512c33.69 0 65.32-8.008 92.85-21.98C565.2 502 596.1 512 632.3 512c3.059 0 5.76-1.725 7.02-4.605c1.229-2.879 .6582-6.148-1.441-8.354C637.6 498.7 615.9 475.3 599.6 443.7zM416 176C416 78.8 322.9 0 208 0S0 78.8 0 176c0 41.63 17.18 79.81 45.73 109.9c-16.34 31.43-38.47 55.57-38.99 55.96c-6.746 7.15-8.635 17.81-4.721 26.98C5.93 378.1 14.97 384 24.95 384c54.18 0 98.32-19.24 128.1-38.22C171.2 349.7 189.3 352 208 352C322.9 352 416 273.2 416 176zM208 304c-14.16 0-28.77-1.689-43.41-5.021L145.4 294.6l-16.72 10.35c-17 10.52-34.42 18.39-52.14 23.57c4.184-6.668 8.191-13.57 11.77-20.45l15.78-30.34L80.57 252.9C65.71 237.3 48 211.2 48 176c0-70.58 71.78-128 160-128s160 57.42 160 128S296.2 304 208 304z",
		width: 640,
	},
	dolly: {
		height: 512,
		path: "M574.6 304c-4.391-12.5-18.11-19.06-30.59-14.69l-16.29 5.725l-70.73-188.6c-4.969-13.28-14.81-23.83-27.72-29.69c-12.89-5.875-27.34-6.391-40.58-1.375l-194.8 73.06L150.8 16.55C147.6 6.672 138.4 0 127.1 0H24C10.75 0 0 10.75 0 24S10.75 48 24 48h86.59l93.8 287.3C177.8 352.3 160 382 160 416c0 53.02 42.98 96 96 96s96-42.98 96-96c0-2.744-.584-5.326-.8105-8.012l208.8-73.35C572.5 330.2 579 316.5 574.6 304zM256 464c-26.47 0-48-21.53-48-48s21.53-48 48-48s48 21.53 48 48S282.5 464 256 464zM335.7 362.6C318.5 336.9 289.2 320 256 320c-2.023 0-3.917 .4727-5.909 .5957L208.8 194.1l89.75-33.65l26.98 71.96C329.1 242.1 338.3 248 348 248c2.797 0 5.656-.4844 8.422-1.531c12.42-4.656 18.7-18.48 14.05-30.89L343.5 143.6l62.1-23.28c2.203-.7969 5.641 .7187 6.484 2.953l70.4 187.7L335.7 362.6z",
		width: 576,
	},
	download: {
		height: 512,
		path: "M448 304h-53.5l-48 48H448c8.822 0 16 7.178 16 16V448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16v-80C48 359.2 55.18 352 64 352h101.5l-48-48H64c-35.35 0-64 28.65-64 64V448c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64v-80C512 332.7 483.3 304 448 304zM432 408c0-13.26-10.75-24-24-24S384 394.7 384 408c0 13.25 10.75 24 24 24S432 421.3 432 408zM239 368.1C243.7 373.7 249.8 376 256 376s12.28-2.344 16.97-7.031l136-136c9.375-9.375 9.375-24.56 0-33.94s-24.56-9.375-33.94 0L280 294.1V24C280 10.75 269.3 0 256 0S232 10.75 232 24v270.1L136.1 199c-9.375-9.375-24.56-9.375-33.94 0s-9.375 24.56 0 33.94L239 368.1z",
		width: 512,
	},
	personDolly: {
		height: 512,
		path: "M80.01 96.01c26.5 0 48.01-21.51 48.01-48.01S106.5 0 80.01 0S32 21.51 32 48.01S53.51 96.01 80.01 96.01zM0 488C0 501.3 10.75 512 24 512c13.25 0 23.1-10.75 23.1-24v-95.88C35.1 382.6 .5 354.3 0 353.9V488zM511.1 385.5c-3.562-12.77-16.75-20.28-29.59-16.64l-143.1 40.08c-10.22-13.17-25.36-22.11-42.76-24.2L239.1 177.7C235.7 164.9 222.7 157.2 209.7 160.8C196.9 164.3 189.3 177.5 192.8 190.3l13.52 49.56H158.2l-31.1-77.5c-8.75-20.88-29-34.5-51.75-34.5h-18.5C25.13 127.9 0 153 0 183.9v102.4c0 7.625 3 29.5 21.25 44l76.49 60.38c5.625 4.5 9.625 10.75 11.25 17.88l19.62 84.75c2.623 11.5 14.37 21.25 28.75 18c12.88-3 21-15.88 18-28.75l-21.37-93c-2.875-12.38-9.875-23.5-19.88-31.38l-38.12-30V214.8l22.25 53.5C123.2 280.3 134.7 288 147.7 288h71.74l29.82 109.3C234 409 223.1 427.3 223.1 448c0 35.35 28.66 64 63.1 64c32.88 0 59.64-24.88 63.26-56.79l143.2-40.1C507.2 411.5 514.7 398.3 511.1 385.5zM287.1 464c-8.82 0-15.1-7.178-15.1-16s7.178-16 15.1-16c8.822 0 16 7.178 16 16S296.8 464 287.1 464zM331.2 360.6c2.25 8.502 11.13 13.63 19.63 11.25l117-31.26c8.502-2.252 13.5-11.13 11.25-19.63l-31.38-116.9c-2.377-8.502-11.13-13.63-19.63-11.25L311.1 224.1c-8.502 2.252-13.63 11.13-11.25 19.63L331.2 360.6z",
		width: 512,
	},
	upload: {
		height: 512,
		path: "M448 304h-128V352h128c8.822 0 16 7.178 16 16V448c0 8.822-7.178 16-16 16H64c-8.822 0-16-7.178-16-16v-80C48 359.2 55.18 352 64 352h128V304H64c-35.35 0-64 28.65-64 64V448c0 35.35 28.65 64 64 64h384c35.35 0 64-28.65 64-64v-80C512 332.7 483.3 304 448 304zM136.1 176.1L232 81.94V352c0 13.25 10.75 24 24 24s24-10.75 24-24V81.94l95.03 95.03C379.7 181.7 385.8 184 392 184s12.28-2.344 16.97-7.031c9.375-9.375 9.375-24.56 0-33.94l-136-136c-9.375-9.375-24.56-9.375-33.94 0l-136 136c-9.375 9.375-9.375 24.56 0 33.94S127.6 186.3 136.1 176.1zM432 408c0-13.26-10.75-24-24-24S384 394.7 384 408c0 13.25 10.75 24 24 24S432 421.3 432 408z",
		width: 512,
	},
};
