import { UPDATE_NOTIFICATIONS } from "redux/constants/Menu";

const initMenu = {
	hibridosComErro: 0,
	consultoresComErro: 0,
	produtoresComErro: 0,
	pedidosComErro: 0,
	comissoesComErro: 0,
	enderecosAValidar: 0,
	somaErrosSap: 0,
};

const menu = (state = initMenu, action) => {
	switch (action.type) {
		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				hibridosComErro: action.hibridosComErro,
				consultoresComErro: action.consultoresComErro,
				produtoresComErro: action.produtoresComErro,
				pedidosComErro: action.pedidosComErro,
				pedidosComErroLead: action.pedidosComErroLead,
				comissoesComErro: action.comissoesComErro,
				enderecosAValidar: action.enderecosAValidar,
				somaErrosSap: action.somaErrosSap,
			};
		default:
			return state;
	}
};

export default menu;
