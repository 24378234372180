import { UPDATE_USER } from "../constants/User";

const initUser = {
	id: null,
	name: null,
	role: null,
	email: null,
	grupo_usuario: null,
};

const user = (state = initUser, action) => {
	switch (action.type) {
		case UPDATE_USER:
			return {
				...state,
				id: action.id,
				name: action.name,
				role: action.role,
				email: action.email,
				grupo_usuario: action?.grupo_usuario,
			};
		default:
			return state;
	}
};
export default user;
