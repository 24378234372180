import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import User from "./User";
import Gui from "./Gui";
import Menu from "./Menu";
import Permissoes from "./Permissoes";

const reducers = combineReducers({
	theme: Theme,
	auth: Auth,
	user: User,
	gui: Gui,
	menu: Menu,
	permissoes: Permissoes,
});

export default reducers;
